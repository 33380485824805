<template>
  <layout :loading="loading">
    <template v-slot:content>
      <t-alert class="my-2" :variant="alert.type" :show="!!alert.message">
        {{ alert.message }}
      </t-alert>
      <h1 class="text-xl py-4 mb-5 border-gray-300 border-b">
        {{ $t("request_new_holiday") }}
      </h1>
      <form class="flex flex-col mx-auto" @submit="onSubmit">
        <div class="flex items-center mb-4">
          <label class="w-24 font-semibold text-sm">{{ $t("from") }}</label>
          <t-datepicker
            class="text-sm"
            :lang="locale"
            v-model="form.from"
            required
          ></t-datepicker>
        </div>
        <div class="flex items-center mb-4">
          <label class="w-24 font-semibold text-sm">{{ $t("until") }}</label>
          <t-datepicker
            class="text-sm"
            :lang="locale"
            v-model="form.until"
            required
          ></t-datepicker>
        </div>
        <div class="flex items-center mb-4">
          <label class="w-24 font-semibold text-sm self-start">{{
            $t("comment")
          }}</label>
          <t-textarea
            class="w-64 md:w-72 h-40 text-sm"
            v-model="form.comment"
            required
          ></t-textarea>
        </div>
        <div class="ml-24">
          <t-button
            class="flex-grow text-sm"
            variant="primary"
            :disabled="loading"
            >{{ $t("edit_save") }}</t-button
          >
        </div>
      </form>
      <h1 class="text-xl pt-6 pb-4 mb-5 border-gray-300 border-b">
        {{ $t("requested_holidays") }}
      </h1>
      <table class="border-gray-300 border mx-auto">
        <thead>
          <th class="border-gray-300 border w-40 py-1">{{ $t("from") }}</th>
          <th class="border-gray-300 border w-40 py-1">{{ $t("until") }}</th>
          <th class="border-gray-300 border w-40 py-1">{{ $t("status") }}</th>
          <th class="border-gray-300 border w-40 py-1"></th>
        </thead>
        <tbody>
          <tr v-for="(holiday, index) in userHolidays" :key="index">
            <td class="text-sm border-gray-300 border pl-2 p-1">
              {{ holiday.from | date }}
            </td>
            <td class="text-sm border-gray-300 border pl-2 p-1">
              {{ holiday.until | date }}
            </td>
            <td
              class="text-sm border-gray-300 border pl-2 p-1"
              :class="holiday.approved ? 'bg-green-100' : 'bg-red-100'"
            >
              {{
                $t(holiday.approved ? "holiday_approved" : "holiday_on_hold")
              }}
            </td>
            <td class="text-sm border-gray-300 border pl-2 p-1">
              <button
                class="text-sm text-blue-500 hover:underline"
                @click="removeExistingUserHoliday(holiday.id)"
              >
                {{ $t("edit_remove") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { getMonthTranslation } from "@/utils/misc";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { actions, getters } from "@/constants/state";
import { get } from 'lodash';

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    alert: {
      type: 'info',
      message: null
    },
    form: {
      from: moment().format("YYYY-MM-DD"),
      until: moment().format("YYYY-MM-DD"),
      comment: null,
    },
  }),
  methods: {
    ...mapActions({
      createUserHoliday: actions.HOLIDAYS_CREATE_USER_HOLIDAY_ACTION,
      removeUserHoliday: actions.HOLIDAYS_REMOVE_USER_HOLIDAY_ACTION,
    }),
    async removeExistingUserHoliday(holidayId) {
      this.loading = true;
      try {
        await this.removeUserHoliday({ id: holidayId, sendMail: 0 });
      } catch (error) {
        console.error("DEBUG: removeExistingUserHoliday", error);
      }
      this.loading = false;
    },
    async onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.alert = {
        type: 'info',
        message: null
      };
      try {
        await this.createUserHoliday({
          ...this.form,
          user_id: this.userId,
          horeca_location_id: this.locationId,
          approved: false,
        });
        this.form = {
          ...this.form,
          comment: null,
        };
        this.alert = {
          type: 'info',
          message: this.$i18n.t('timeout_request_submitted')
        };
      } catch (error) {
        if (error.status === 400 && get(error, 'data.errors.0')) {
          this.alert = {
            type: 'danger',
            message: this.$i18n.t(get(error, 'data.errors.0'))
          };
        }
        console.error("DEBUG: createUserHoliday", error);
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      locale: getters.SESSION_LOCALE_GETTER,
      userHolidays: getters.HOLIDAYS_USER_HOLIDAYS_GETTER,
      userId: getters.AUTH_USER_ID_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
    days() {
      return Array.from(Array(31).keys(), (_, i) => `${i + 1}`.padStart(2, 0));
    },
    months() {
      return Array.from(Array(12).keys(), (_, i) => getMonthTranslation(i + 1));
    },
    years() {
      return Array.from(Array(6).keys(), (_, i) => moment().year() + i);
    },
  },
  filters: {
    date(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  max-width: 50rem;
}
</style>
